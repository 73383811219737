import ConstantAPI from '@/config/ConstantAPI';
import {getField, updateField} from 'vuex-map-fields';
import mutations from './mutations';
import {FORM_MODE, TYPE_NOTE_DECLARATION} from '@/util/common-constant.js';
import {DataService} from "@/dataService/dataService";
import {checkResponse} from "@/util/common-utils";
import {notification} from "ant-design-vue";
import {MESSAGES} from "@/util/message-notification";
import {showConfirm} from "@/util/confirm";

const state = () => ({
  loading: false,
  api: ConstantAPI.noteDeclaration,
  listAll: [],
  formMode: FORM_MODE.VIEW,
  formState: null,
});

const actions = {
  setFormState({state}, listLanguage) {
    const lstluuYKhaiBao = listLanguage.map(e => {
      return {
        maNgonNgu: e.ma,
        noiDung: null,
        tieuDe: null,
      }
    })
    state.formState = {
      luuYKhaiBao: lstluuYKhaiBao,
      hienThi: 0,
    }
  },
  preCreate({ state, dispatch }, listLanguage) {
    dispatch('setFormState', listLanguage)
    state.formMode = FORM_MODE.CREATE;
  },
  preUpdate({ state }) {
    state.formMode = FORM_MODE.UPDATE;
  },
  preView({ state }, data) {
    state.formState = data;
    state.formMode = FORM_MODE.VIEW;
  },
  async getAll({ commit, state }) {
    try {
      commit('setLoading', true);
      const response = await DataService.callApi(state.api.GET_ALL);
      checkResponse(response, () => {
        state.listAll = TYPE_NOTE_DECLARATION.map(e => ({
          ...e,
          children: response.data.filter(i => i.type === e.type).length ? response.data.filter(i => i.type === e.type) : null
        }))
      });
      commit('setLoading', false);
    } catch (err) {
      console.log(err);
    }
  },
  async getDetail({ state }, params) {
    try {
      let isSuccess = false
      const response = await DataService.callApi({
        url: `${state.api.GET_BY_CODE.url}/${params.maLuuY}`,
        method: state.api.GET_BY_CODE.method
      });
      checkResponse(response, () => {
        state.formState = response.data
        if (params.listLanguage && params.listLanguage.length >= 1 && state.formState.luuYKhaiBao && state.formState.luuYKhaiBao.length >= 1) {
          const lstMaNgonNguForm = state.formState.luuYKhaiBao.map(e => e.maNgonNgu)
          params.listLanguage.forEach(e => {
            if (!lstMaNgonNguForm.includes(e.ma)) {
              state.formState.luuYKhaiBao.push({
                maNgonNgu: e.ma,
                noiDung: null,
                tieuDe: null,
              })
            }
          })
        }
        isSuccess = true;
      });
      return isSuccess
    } catch (err) {
      console.log(err);
    }
  },
  async create({ commit, state }, payload) {
    try {
      let isSuccess = false;
      commit('setLoading', true);
      const response = await DataService.callApi(state.api.CREATE, payload);
      commit('setLoading', false);
      checkResponse(response, () => {
        notification.success({
          message: MESSAGES.MESSAGES_0036,
          description: MESSAGES.MESSAGE_005,
          duration: 4,
        });
        isSuccess = true;
      });
      return isSuccess;
    } catch (err) {
      console.log(err);
    }
  },
  async update({ commit, state }, payload) {
    try {
      let isSuccess = false;
      commit('setLoading', true);
      const response = await DataService.callApi(state.api.UPDATE, payload);
      commit('setLoading', false);
      checkResponse(response, () => {
        notification.success({
          message: MESSAGES.MESSAGES_0036,
          description: MESSAGES.MESSAGE_007,
          duration: 4,
        });
        isSuccess = true;
      });
      return isSuccess;
    } catch (err) {
      console.log(err);
    }
  },
  async delete({ state }, rows) {
    try {
      if (await showConfirm(MESSAGES.MESSAGES_0017)) {
        const response = await DataService.delete(
            state.api.DELETE.url + rows.join(',')
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: MESSAGES.MESSAGES_0036,
            description: 'Xóa Bài viết thành công',
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
    }
  },
  async changActive({ commit, state }, payload) {
    try {
      commit('setLoading', true);
      if (await showConfirm(MESSAGES.MESSAGES_0034)) {
        const response = await DataService.callApi(
            state.api.CHANGE_STATUS, payload
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: MESSAGES.MESSAGES_0036,
            description: MESSAGES.MESSAGES_0035,
            duration: 4,
          });
        });
        return success;
      }
      commit('setLoading', false);
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
