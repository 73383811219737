import { getField, updateField } from 'vuex-map-fields';
import mutations from './mutations';
import { TYPE_EMAIL_FOREIGNERS } from '@/util/common-constant';
const state = () => ({
  isSendMailForm:TYPE_EMAIL_FOREIGNERS.DEFAULT
});

const actions = {
  handleSendMailForm({ state }, value) {
    state.isSendMailForm = value 
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
