import { createStore } from 'vuex';
import themeLayout from './modules/themeLayout/actionCreator';
import auth from './modules/authentication/axios/actionCreator';
import role from './modules/system/role/actionCreator';
import menu from './modules/system/menu/actionCreator';
import user from './modules/system/user/actionCreator';
import chartContent from './modules/chartContent/actionCreator';
import gate from './modules/gate/actionCreator';
import purposeOfEntry from './modules/purpose-of-entry/actionCreator';
import tariff from './modules/tariff/actionCreator';
import visaSymbol from './modules/visa-symbol/actionCreator';
import passportType from './modules/passport-type/actionCreator';
import fileApproval from './modules/file-approval/actionCreator';
import profileType from './modules/profile-type/actionCreator';
import signProfile from './modules/sign-profile/actionCreator';
import visaCancel from './modules/visa-cancel/actionCreator';
import profileNew from './modules/profile-new/actionCreator';
import dataset from './modules/dataset/actionCreator';
import province from './modules/province/actionCreator';
import fileAssignment from './modules/file-assignment/actionCreator';
import additionalRequest from './modules/additional-request/actionCreator';
import searchInfoProfile from './modules/search-info-profile/actionCreator';
import watchProfile from './modules/watch-profile/actionCreator';
import archivedRecords from './modules/archived-records/actionCreator';
import configCriteria from './modules/config-criteria/actionCreator';
import unit from './modules/unit/actionCreator';
import international from './modules/international/actionCreator';
import district from './modules/district/actionCreator';
import wards from './modules/wards/actionCreator';
import profileDetail from './modules/profile-detail/actionCreator';
import exchangeRate from './modules/exchange-rate/actionCreator';
import warning from './modules/warning/actionCreator';
import security from './modules/security/actionCreator';
import log from './modules/log/actionCreator';
import control from './modules/control/actionCreator';
import unitManagement from './modules/unit-management/actionCreator';
import countryGroup from './modules/country-group/actionCreator';
import followDirective from './modules/follow-directive/actionCreator';
import purposeStatistics from './modules/dash-board/purpose-statistics/actionCreator';
import processingStatus from './modules/dash-board/processing-status/actionCreator';
import temporaryAddress from './modules/dash-board/temporary-address/actionCreator';
import email from './modules/dash-board/email/actionCreator';
import visaRate from './modules/dash-board/visa-rate/actionCreator';
import visaPeriod from './modules/dash-board/visa-period/actionCreator';
import visaValue from './modules/dash-board/visa-value/actionCreator';
import declareViolations from './modules/dash-board/declare-violations/actionCreator';
import article from './modules/article/actionCreator';
import faq from './modules/faq/actionCreator';
import instruction from './modules/instruction/actionCreator';
import popup from './modules/popup/actionCreator';
import language from './modules/language/actionCreator';
import userManual from './modules/user-manual/actionCreator';
import contact from './modules/contact/actionCreator';
import posts from './modules/posts/actionCreator';
import noteDeclaration from './modules/note-declaration/actionCreator';
import organization from './modules/organization/actionCreator';
import emailContent from './modules/email/actionCreator';
import synchronousControl from './modules/synchronous-control/actionCreator';
import emailSend from './modules/email-foreigner/actionCreator';
import leader from './modules/leader/actionCreator';
export default createStore({
  modules: {
    themeLayout,
    auth,
    dataset,
    role,
    menu,
    user,
    chartContent,
    gate,
    purposeOfEntry,
    tariff,
    visaSymbol,
    passportType,
    fileApproval,
    profileType,
    signProfile,
    visaCancel,
    profileNew,
    province,
    fileAssignment,
    additionalRequest,
    searchInfoProfile,
    watchProfile,
    archivedRecords,
    configCriteria,
    unit,
    international,
    district,
    wards,
    profileDetail,
    exchangeRate,
    warning,
    security,
    log,
    control,
    unitManagement,
    countryGroup,
    followDirective,
    purposeStatistics,
    visaRate,
    visaPeriod,
    visaValue,
    declareViolations,
    processingStatus,
    temporaryAddress,
    email,
    article,
    faq,
    instruction,
    popup,
    language,
    userManual,
    contact,
    posts,
    noteDeclaration,
    organization,
    emailContent,
    synchronousControl,
    emailSend,
    leader,
  },
});
