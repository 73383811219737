export default {
    setLoading(state, loading) {
        state.loading = loading;
    },
    setAll(state, data) {
        data.forEach(e => state.listAllKeyValue[e.maQT] = e.tenQT)
        state.listAll = data.map(e => ({
            ...e,
            tenQT: `${e.maQT} - ${e.tenQTEn}`
        }));
    },
}
