<template>
  <a-config-provider :locale="locale">
    <!-- <img src="require('/src/static/img/Logo_Dark.png')" alt=""/> -->
    <div v-if="isLoading" class="spin md:h-full" style="height: 100vh">
      <a-spin />
    </div>
    <ThemeProvider class="h-auto md:h-full" v-else :theme="{
      ...theme,
      rtl,
      topMenu,
      darkMode,
    }">
      <Suspense class="h-full">
        <template #default>
          <router-view></router-view>
        </template>
        <template #fallback>
          <div class="spin">
            <a-spin />
          </div>
        </template>
      </Suspense>
    </ThemeProvider>
  </a-config-provider>
</template>
<script>
import { ThemeProvider } from 'vue3-styled-components';
import { theme } from './config/theme/themeVariables';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import vi_VN from 'ant-design-vue/es/locale/vi_VN';

dayjs.locale('vi');

export default defineComponent({
  name: 'App',
  components: {
    ThemeProvider,
  },
  setup() {
    const { state, dispatch } = useStore();
    const rtl = computed(() => state.themeLayout.rtlData);
    const isLoading = ref(true);
    const darkMode = computed(() => state.themeLayout.data);
    const topMenu = computed(() => state.themeLayout.topMenu);
    const isFbAuthLoading = computed(() => state.firebase.loading);
    const file = ref(null);
    onMounted(async () => {
      try {
        await dispatch('getUserInfo');
      } finally {
        isLoading.value = false;
      }
    });

    return {
      theme,
      rtl,
      darkMode,
      topMenu,
      isLoading,
      isFbAuthLoading,
      locale: vi_VN,
      file,
    };
  },
});
</script>
